<template>
    <div>
        <b-modal hide-footer hide-header centered id="hello-ty-modal" title="BootstrapVue">
            <h2 class="my-4 text-center">
                <span>{{ $t("global.thankyou") }}</span>
            </h2>
        </b-modal>
        <b-modal hide-footer hide-header centered id="service-ty-modal" title="BootstrapVue">
            <h2 class="my-4 text-center">
                <span>{{ $t("global.thankyou") }}</span>
            </h2>
            <p class="text-center">{{ $t("thankyou.message") }}</p>
        </b-modal>
    </div>
</template>
<script>
export default { name: 'ThankYouModal' }
</script>
<style lang="scss">
#hello-ty-modal,
#service-ty-modal {
    h2 {
        color: $bronze;
    }
}
</style>
